import React, {useState} from 'react'
import {Helmet} from 'react-helmet'
import styles from '../styles/index.css'

const CONSOLE_URL = process.env.GATSBY_CONSOLE_URL || ''

export default function Index() {
  const [displayMenu, setDisplayMenu] = useState(false)

  return (
    <div>
      <Helmet>
        <title>Service Mocks - Fake APIs to help debug and test</title>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css"/>
      </Helmet>
      <div className="relative bg-gray-50">
        <div className="relative bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="w-0 flex-1 flex">
                <h1 className='text-xl font-bold text-gray-700'>Service Mocks</h1>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <button type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                        onClick={() => setDisplayMenu((true))}>
                  {/*Heroicon name: menu */}
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
                  </svg>
                </button>
              </div>
              <nav className="hidden md:flex space-x-10">
                <a href="#pricing"
                   className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150">
                  Pricing
                </a>
                <a href="#features"
                   className="text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 transition ease-in-out duration-150">
                  Features
                </a>
                <a href="#usecases"
                   className="text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 transition ease-in-out duration-150">
                  Use Cases
                </a>
                <a href={`${CONSOLE_URL}/c/docs`}
                   className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150">
                  Docs
                </a>
              </nav>
              <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
                <span className="inline-flex rounded-md shadow-sm">
                  <a href={`${CONSOLE_URL}/c/services`}
                     className="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150">
                    Console
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/*Mobile menu, show/hide based on mobile menu state.*/}

          {/*Entering: "duration-200 ease-out"*/}
          {/*  From: "opacity-0 scale-95"*/}
          {/*  To: "opacity-100 scale-100"*/}
          {/*Leaving: "duration-100 ease-in"*/}
          {/*  From: "opacity-100 scale-100"*/}
          {/*  To: "opacity-0 scale-95"*/}
          {
            displayMenu && (
              <div className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden">
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5 space-y-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <h1 className='text-xl font-bold text-gray-700'>Service Mocks</h1>
                        </div>
                        <div className="-mr-2">
                          <button type="button"
                                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                                  onClick={() => setDisplayMenu(false)}>
                            {/*Heroicon name: x */}
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        <nav className="grid gap-y-8">
                          <a href="#pricing"
                             className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Pricing
                            </div>
                          </a>
                          <a href="#features"
                             className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">

                            <div className="text-base leading-6 font-medium text-gray-900">
                              Features
                            </div>
                          </a>
                          <a href="#usecases"
                             className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Use Cases
                            </div>
                          </a>
                          <a href={`${CONSOLE_URL}/c/docs`}
                             className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Docs
                            </div>
                          </a>
                          <a href={`${CONSOLE_URL}/c/services`}
                             className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150">
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Console
                            </div>
                          </a>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>

        <main className="lg:relative">
          <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
            <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
              <h2
                className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                Fake APIs
                <br className="xl:hidden"/>
                <span className="text-gray-600"> to help debug and test</span>
              </h2>
              <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                Simulate APIs you don't control to enable test scenarios you need.
              </p>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a href={`${CONSOLE_URL}/c/services`}
                     className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Get started
                  </a>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a href={`${CONSOLE_URL}/c/help?topic=request-demo`}
                     className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-600 bg-white hover:text-gray-500 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Request Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                 src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
                 alt=""/>
          </div>
        </main>
      </div>
      <div className="py-12 bg-white" id="features">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h3
              className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Powerful Features
            </h3>
            <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
              Unbelievably simple to use
            </p>
          </div>

          <div className="mt-10">
            <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <li>
                <div className="flex">
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Import Swagger 2.0/OAS 3.X</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Import an Open API specification file (yaml/json) and automatically generate
                      an API mock with associated requests, responses, and data attributes
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Export Swagger 2.0/OAS 3.X</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      We infer an Open API contract from the API mock resources, requests, and responses
                      you define in the associated API mock.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Configure Active Responses</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Explicitly toggle responses on/off or use our JavaScript API to activate responses based on
                      request headers,
                      query parameters, path parameters or body contents
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Proxy Requests/Responses</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Wedge API mocks between clients and destination APIs you don't control to pass through
                      real requests/responses by default while allowing you to script conditional
                      fake responses you normally couldn't produce.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Simulate Delays/Disconnects</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Add a delay or disconnect to API mock responses to test API client behavior in
                      either case
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Log HTTP Requests</h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Send requests to an API mock and evaluate HTTP request/response status codes, parameters,headers,
                      and bodies
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bg-white" id="usecases">
        <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
          <div className="lg:text-center">
            <h3
              className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Use Cases
            </h3>
            <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
              For Developers, Testers, and PMs
            </p>
          </div>
          <div className="mt-6 border-t-2 border-gray-100 pt-10">
            <dl className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Design Your API First And Unblock Teams
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      Talking about how an API might work is never as informative
                      as trying it first hand. Use Service Mocks as a tool for API
                      clients and providers to agree on the design of an API
                      rather than churning through your design in code. As soon as the design
                      is finished consumers can work from an API fake to start.
                    </p>
                  </dd>
                </div>
                <div className="mt-12">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Test Sad Path Scenarios
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      It's difficult to get the APIs you depend on to return errors.
                      Use Service Mocks to create a Fake API and point your application
                      or service to the mock for testing. You can work through your error
                      and timeout tests by activating the appropriate mock responses as needed
                    </p>
                  </dd>
                </div>
                <div className="mt-12">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Make Your Test Environment More Predictable
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      Setup API fakes and deploy them as Proxies between your clients
                      and the services they call. Then use Service Mocks' powerful scripting feature to activate
                      fake responses based on request data (e.g. email address) or default to passing
                      along the requests and responses to or from the real services.
                    </p>
                  </dd>
                </div>
              </div>
              <div className="mt-12 md:mt-0">
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Publish Documentation For Your APIs
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      One of the biggest complaints in API development is the lack of documentation.
                      Use Service Mocks to publish documentation in the Swagger or Redoc Open Source formats
                      and share links with other teams in your organization or third party partners.
                    </p>
                  </dd>
                </div>
                <div className="mt-12">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Debug HTTP Requests
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      You can quickly setup an API Fake endpoint with Service Mocks and view request logs
                      that will show you everything you send in the request and return in a response
                      including headers, path/query params, the req/res bodies and status codes.
                    </p>
                  </dd>
                </div>
                <div className="mt-12">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Source Control And Continuous Integration
                  </dt>
                  <dd className="mt-2">
                    <p className="text-base leading-6 text-gray-500">
                      Already defining Swagger 2.0 or Open API 3.X contract definitions in your
                      API development workflow? Easily automate the creation of mock definitions
                      via the Service Mocks API or CLI in your Continuous Integration pipelines by
                      source controlling and pushing them to Service Mocks automatically
                    </p>
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-gray-900" id="pricing">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                Pricing
              </h2>
              <p
                className="text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                The right price for you, whoever you are
              </p>
              <p className="text-xl leading-7 text-gray-300">
                We want to find the balance of price and value for you and your team
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 h-3/4 bg-gray-900"></div>
            <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto space-y-4 lg:grid lg:grid-cols-1 lg:gap-5 lg:space-y-0">
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-gray-100 text-gray-600"
                        id="tier-standard">
                        Custom Plan
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-3xl leading-none font-extrabold">
                      Flexible Pricing
                    </div>
                    <p className="mt-5 text-lg leading-7 text-gray-500">
                      All features included
                    </p>
                  </div>
                  <div
                    className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          {/*Heroicon name: check */}
                          <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 text-gray-700">
                          3 free API mocks to start
                        </p>
                      </li>
                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          {/*Heroicon name: check */}
                          <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 text-gray-700">
                          Up to 5 users as you explore
                        </p>
                      </li>
                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          {/*Heroicon name: check */}
                          <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 text-gray-700">
                          Flexible API mock count limit
                        </p>
                      </li>

                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          {/*Heroicon name: check */}
                          <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 text-gray-700">
                          Flexible user count limit
                        </p>
                      </li>

                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          {/*Heroicon name: check */}
                          <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                          </svg>
                        </div>
                        <p className="ml-3 text-base leading-6 text-gray-700">
                          Flexible API mock daily request limit
                        </p>
                      </li>
                    </ul>
                    <div className="rounded-md shadow">
                      <a href={`${CONSOLE_URL}/c/help?topic=request-demo`}
                         className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                         aria-describedby="tier-standard">
                        Let's Talk
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="bg-gray-900">*/}
      {/*  <div className="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">*/}
      {/*    <div className="text-center">*/}
      {/*      <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">*/}
      {/*        Pricing*/}
      {/*      </h2>*/}
      {/*      <p*/}
      {/*        className="mt- text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">*/}
      {/*        Powerful Tool*/}
      {/*      </p>*/}
      {/*      <p className="mt-3 max-w-4xl mx-auto text-xl leading-7 text-gray-300 sm:mt-5 sm:text-2xl sm:leading-8">*/}
      {/*        At an affordable price*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">*/}
      {/*    <div className="relative z-0">*/}
      {/*      <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div>*/}
      {/*      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">*/}
      {/*        <div className="relative lg:grid lg:grid-cols-7">*/}
      {/*          <div*/}
      {/*            className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">*/}
      {/*            <div*/}
      {/*              className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">*/}
      {/*              <div className="flex-1 flex flex-col">*/}
      {/*                <div className="bg-white px-6 py-10">*/}
      {/*                  <div>*/}
      {/*                    <h3 className="text-center text-2xl leading-8 font-medium text-gray-900" id="tier-hobby">*/}
      {/*                      Starting*/}
      {/*                    </h3>*/}
      {/*                    <div className="mt-4 flex items-center justify-center">*/}
      {/*                <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">*/}
      {/*                  <span className="mt-2 mr-2 text-4xl font-medium">*/}
      {/*                    $*/}
      {/*                  </span>*/}
      {/*                  <span className="font-extrabold">*/}
      {/*                    24.99*/}
      {/*                  </span>*/}
      {/*                </span>*/}
      {/*                      <span className="text-xl leading-7 font-medium text-gray-500">*/}
      {/*                  /month*/}
      {/*                </span>*/}
      {/*                    </div>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*                <div*/}
      {/*                  className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">*/}
      {/*                  <ul>*/}
      {/*                    <li className="flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        30 day free trial*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                    <li className="mt-4 flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        10 API mocks*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                    <li className="mt-4 flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        10 users*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                    <li className="mt-4 flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        All features*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                  </ul>*/}
      {/*                  <div className="mt-8">*/}
      {/*                    <div className="rounded-lg shadow-md">*/}
      {/*                      <a href="#"*/}
      {/*                         className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"*/}
      {/*                         aria-describedby="tier-hobby">*/}
      {/*                        Start your trial*/}
      {/*                      </a>*/}
      {/*                    </div>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">*/}
      {/*            <div className="relative z-10 rounded-lg shadow-xl">*/}
      {/*              <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-gray-600"></div>*/}
      {/*              <div className="absolute inset-x-0 top-0 transform translate-y-px">*/}
      {/*                <div className="flex justify-center transform -translate-y-1/2">*/}
      {/*            <span*/}
      {/*              className="inline-flex rounded-full bg-gray-600 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">*/}
      {/*              Most popular*/}
      {/*            </span>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*              <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">*/}
      {/*                <div>*/}
      {/*                  <h3 className="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6"*/}
      {/*                      id="tier-growth">*/}
      {/*                    Growing*/}
      {/*                  </h3>*/}
      {/*                  <div className="mt-4 flex items-center justify-center">*/}
      {/*              <span*/}
      {/*                className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">*/}
      {/*                <span className="mt-2 mr-2 text-4xl font-medium">*/}
      {/*                  $*/}
      {/*                </span>*/}
      {/*                <span className="font-extrabold">*/}
      {/*                  99.99*/}
      {/*                </span>*/}
      {/*              </span>*/}
      {/*                    <span className="text-2xl leading-8 font-medium text-gray-500">*/}
      {/*                /month*/}
      {/*              </span>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*              <div*/}
      {/*                className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">*/}
      {/*                <ul>*/}
      {/*                  <li className="flex items-start">*/}
      {/*                    <div className="flex-shrink-0">*/}
      {/*                      <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                           viewBox="0 0 24 24">*/}
      {/*                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                              d="M5 13l4 4L19 7"/>*/}
      {/*                      </svg>*/}
      {/*                    </div>*/}
      {/*                    <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                      30 day free trial*/}
      {/*                    </p>*/}
      {/*                  </li>*/}
      {/*                  <li className="mt-4 flex items-start">*/}
      {/*                    <div className="flex-shrink-0">*/}
      {/*                      <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                           viewBox="0 0 24 24">*/}
      {/*                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                              d="M5 13l4 4L19 7"/>*/}
      {/*                      </svg>*/}
      {/*                    </div>*/}
      {/*                    <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                      10 API mocks*/}
      {/*                    </p>*/}
      {/*                  </li>*/}
      {/*                  <li className="mt-4 flex items-start">*/}
      {/*                    <div className="flex-shrink-0">*/}
      {/*                      <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                           viewBox="0 0 24 24">*/}
      {/*                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                              d="M5 13l4 4L19 7"/>*/}
      {/*                      </svg>*/}
      {/*                    </div>*/}
      {/*                    <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                      10 users*/}
      {/*                    </p>*/}
      {/*                  </li>*/}
      {/*                  <li className="mt-4 flex items-start">*/}
      {/*                    <div className="flex-shrink-0">*/}
      {/*                      <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                           viewBox="0 0 24 24">*/}
      {/*                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                              d="M5 13l4 4L19 7"/>*/}
      {/*                      </svg>*/}
      {/*                    </div>*/}
      {/*                    <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                      All features*/}
      {/*                    </p>*/}
      {/*                  </li>*/}
      {/*                </ul>*/}
      {/*                <div className="mt-10">*/}
      {/*                  <div className="rounded-lg shadow-md">*/}
      {/*                    <a href="#"*/}
      {/*                       className="block w-full text-center rounded-lg border border-transparent bg-gray-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150"*/}
      {/*                       aria-describedby="tier-growth">*/}
      {/*                      Start your trial*/}
      {/*                    </a>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">*/}
      {/*            <div*/}
      {/*              className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">*/}
      {/*              <div className="flex-1 flex flex-col">*/}
      {/*                <div className="bg-white px-6 py-10">*/}
      {/*                  <div>*/}
      {/*                    <h3 className="text-center text-2xl leading-8 font-medium text-gray-900" id="tier-scale">*/}
      {/*                      Scaling*/}
      {/*                    </h3>*/}
      {/*                    <div className="mt-4 flex items-center justify-center">*/}
      {/*                <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">*/}
      {/*                  <span className="mt-2 mr-2 text-4xl font-medium">*/}
      {/*                    $*/}
      {/*                  </span>*/}
      {/*                  <span className="font-extrabold">*/}
      {/*                    199.99*/}
      {/*                  </span>*/}
      {/*                </span>*/}
      {/*                      <span className="text-xl leading-7 font-medium text-gray-500">*/}
      {/*                  /year*/}
      {/*                </span>*/}
      {/*                    </div>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*                <div*/}
      {/*                  className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">*/}
      {/*                  <ul>*/}
      {/*                    <li className="flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        30 day free trial*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                    <li className="mt-4 flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        10 API mocks*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                    <li className="mt-4 flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        10 users*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                    <li className="mt-4 flex items-start">*/}
      {/*                      <div className="flex-shrink-0">*/}
      {/*                        <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none"*/}
      {/*                             viewBox="0 0 24 24">*/}
      {/*                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
      {/*                                d="M5 13l4 4L19 7"/>*/}
      {/*                        </svg>*/}
      {/*                      </div>*/}
      {/*                      <p className="ml-3 text-base leading-6 font-medium text-gray-500">*/}
      {/*                        All features*/}
      {/*                      </p>*/}
      {/*                    </li>*/}
      {/*                  </ul>*/}
      {/*                  <div className="mt-8">*/}
      {/*                    <div className="rounded-lg shadow-md">*/}
      {/*                      <a href="#"*/}
      {/*                         className="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"*/}
      {/*                         aria-describedby="tier-scale">*/}
      {/*                        Start your trial*/}
      {/*                      </a>*/}
      {/*                    </div>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

